import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import CustomLink from "../components/CustomLink";

class EditorsHero extends React.Component {
  render() {
    const section  = this.props.page.frontmatter;

    return (
      <div className="section hero-editeurs flex-column">
        <div className="editeurs-hero-bg">
          <img src="/img/editeurs-hero-bg.svg" alt="wavy background" />
        </div>
        <div className="editeurs-hero-bg-mobile">
          <img src="/img/editeurs-hero-bg-mobile.svg" alt="wavy background" />
        </div>
        <div className="editeurs-hero-image">
          <img src="/img/editeurs-hero-img.svg" alt="Browser window and books"/>
        </div>
        <div className="content-wrapper flex-row justc-end alitems-center flex-1 editeurs-hero-text-wrapper
                                        ">
          <div className="editeurs-hero-text">
            <div className="title-3 bold-weight text-medium mgb-s">{section.hero.title}</div>
            <ReactMarkdown children={section.hero.subtitle} className="title-5 text-light"/>
          </div>
        </div>
      </div>
    )
  }
}

class EditorCards extends React.Component {
  render() {
    const section = this.props.page.frontmatter;

    return (
      <div className="section editor-cards-section">
        <div className="content-wrapper flex-row justc-space-b alitems-stretch
                                              m-flex-column m-alitems-center">
          {section.editorTypes.map((editorType,index) => (
            <div key={index} className="editor-card one-third-width flex-column justc-space-b alitems-center
                                             m-full-width m-mgb-s m-padding-horizontal-m m-padding-vertical-m
                                             s-padding-horizontal-s s-padding-vertical-s">
              <div className="full-width">
                <div className="title-4 bold-weight text-medium">{editorType.title}</div>
                <div className="card-divider mgt-s mgb-s"></div>
                <div className="medium-weight">{editorType.listTitle}</div>
                <ul className="mgt-xs arrow-list">
                  {editorType.list.map((listItem, index) => (
                    <li key={index}>
                      {listItem}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mgt-m">
                <CustomLink linkType="internal"
                            linkURL={editorType.linkURL}
                            className="cta multiline fill blue">
                            {editorType.ctaValue}
                </CustomLink>
              </div>
            </div>
          ))}
        </div>
        <div className="content-wrapper mgt-s">
          <div className="editor-login one-third-width center-text text-light mgt-xs
                                       m-dspl-none">
                  Déja inscrit ?&nbsp;
                  <CustomLink linkType="external-blank"
                              linkURL={process.env.LOGIN_URL}
                              className="text-light">
                    Connectez-vous ici
                  </CustomLink>
          </div>
        </div>
      </div>
    )
  }
}

export const EditeursPageTemplate = ({ page }) => {
  return (
    <div>
      <EditorsHero page={page}/>
      <EditorCards page={page}/>
    </div>
  )
}

const EditeursPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    },
  } = page; 

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <EditeursPageTemplate page={page} />
    </Layout>
  )
}

EditeursPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};


export default EditeursPage;

export const pageQuery = graphql`
  query EditorsPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        hero {
          title
          subtitle
        }
        editorTypes {
          title
          listTitle
          list
          ctaValue
          linkURL
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
